export default defineAppConfig({
  // ui: {
  //   button: {
  //     default: {
  //       color: 'black',
  //       size: 'md',
  //     },
  //     rounded: 'rounded-full',
  //   },
  //   footer: {
  //     bottom: {
  //       wrapper: 'border-t border-gray-200 dark:border-gray-800',
  //     },
  //     top: {
  //       container: 'py-8 lg:py-16',
  //       wrapper: 'border-t border-gray-200 dark:border-gray-800',
  //     },
  //   },
  //   gray: 'neutral',
  //   header: {
  //     links: {
  //       active: 'text-gray-900 dark:text-white after:opacity-100',
  //       base: 'py-2 px-4 font-light text-lg transition-colors relative after:absolute after:-bottom-px after:inset-x-2 after:h-px after:rounded-full after:opacity-0 after:bg-gray-900 dark:after:bg-white after:transition-opacity',
  //       inactive: 'text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200',
  //     },
  //     wrapper: 'lg:!border-transparent bg-transparent dark:bg-transparent dark:border-gray-300',
  //   },
  //   icons: {
  //     check: 'i-mdi-check',
  //     chevron: 'i-mdi-chevron-down',
  //     close: 'i-mdi-close',
  //     dark: 'i-mdi-lightbulb-off-outline',
  //     external: 'i-mdi-external-link',
  //     hash: 'i-mdi-hashtag',
  //     light: 'i-mdi-lightbulb-outline',
  //     loading: 'i-mdi-sync',
  //     menu: 'i-mdi-menu',
  //     search: 'i-mdi-search',
  //     system: 'i-mdi-computer',
  //   },
  //   input: {
  //     rounded: 'rounded-full',
  //   },
  //   pricing: {
  //     card: {
  //       features: {
  //         item: {
  //           icon: {
  //             base: 'text-gray-900 dark:text-white',
  //           },
  //         },
  //       },
  //       highlight: 'ring-gray-900 dark:ring-white',
  //     },
  //   },
  //   primary: 'orange',
  //   variables: {
  //     dark: {
  //       background: 'var(--color-gray-950)',
  //     },
  //     light: {
  //       background: 'var(--color-gray-50)',
  //     },
  //   },
  // },
  ui: {
    button: {
      default: {
        color: 'primary',
        size: 'md',
      },
      font: 'font-serif font-semibold',
      rounded: 'rounded-none',
    },
    gray: 'neutral',
    header: {
      links: {
        base: 'py-2 px-4 font-semibold text-lg text-primary-400 font-serif',
      },
    },
    icons: {
      check: 'i-mdi-check',
      chevron: 'i-mdi-chevron-down',
      close: 'i-mdi-close',
      dark: 'i-mdi-lightbulb-off-outline',
      external: 'i-mdi-external-link',
      hash: 'i-mdi-hashtag',
      light: 'i-mdi-lightbulb-outline',
      loading: 'i-mdi-sync',
      menu: 'i-mdi-menu',
      search: 'i-mdi-search',
      system: 'i-mdi-computer',
    },
    primary: 'orange',
  },
})
